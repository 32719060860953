import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import CallInsightsTable from "../../tables/CallInsights/CallInsightsTable";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { insightsByLocation } from "../../redux/Insights/insightsAction";
import dialog from "../../assets/Message Detail Placeholder Icon.svg";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { handleInsightsFilter } from "../../redux/Insights/insights";

const CallInsights = () => {
  const dispatch = useAppDispatch();
  const today = new Date();
  const formatDate = (date: Date) => date.toISOString().split("T")[0];

  const filters = [
    {
      name: "Last 7 days",
      from: formatDate(
        new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
      ),
      to: formatDate(today),
    },
    {
      name: "Last 28 days",
      from: formatDate(
        new Date(today.getFullYear(), today.getMonth(), today.getDate() - 27)
      ),
      to: formatDate(today),
    },
    {
      name: "This month",
      from: formatDate(new Date(today.getFullYear(), today.getMonth(), 1)),
      to: formatDate(today),
    },
    {
      name: "Last 90 days",
      from: formatDate(
        new Date(today.getFullYear(), today.getMonth(), today.getDate() - 89)
      ),
      to: formatDate(today),
    },
    {
      name: "Lifetime",
      from: "",
      to: formatDate(today),
    },
    {
      name: "Last year",
      from: formatDate(
        new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())
      ),
      to: formatDate(today),
    },
    {
      name: "Custom",
      from: "",
      to: "",
    },
  ];

  const [toggleFilter, setToggleFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    from: "",
    to: formatDate(new Date()),
    name: "Lifetime",
  });
  const { insightsTopicTotal } = useAppSelector((state) => state.farmer);
  const { insightsByLocationData, insightsByLocationLoading, insightsFilter } =
    useAppSelector((state) => state.insights);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [customOpen, setCustomOpen] = useState(false);

  const handleSaveCustomDate = () => {
    setSelectedFilter({
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
      name: "Custom",
    });
    dispatch(
      handleInsightsFilter({
        from: moment(startDate).format("YYYY-MM-DD"),
        to: moment(endDate).format("YYYY-MM-DD"),
        name: "Custom",
      })
    );
    setToggleFilter(false);
    setCustomOpen(false);
    setStartDate(new Date());
    setEndDate(null);
  };

  const getDaysSelected = () => {
    if (!startDate || !endDate) return 0;
    return moment(endDate).diff(moment(startDate), "days") + 1;
  };

  useEffect(() => {
    dispatch(
      insightsByLocation({
        from: insightsFilter?.from,
        to: insightsFilter?.to,
      })
    );
  }, [dispatch, insightsFilter]);

  return (
    <div className="relative">
      <Header
        title="Call Insights"
        btn={
          <div className="relative">
            <div
              onClick={() => setToggleFilter(!toggleFilter)}
              className={`h-[44px] flex justify-center items-center px-3 rounded-lg ${
                toggleFilter
                  ? "border-primary shadow-[#D5E8D6] shadow-sm text-[#5F6D60] border font-semibold text-sm"
                  : "border-[#E1E6E1] border text-[#5F6D60] font-semibold text-sm"
              }  gap-1 cursor-pointer `}
            >
              <p className=" ">{insightsFilter?.name || "Lifeime"}</p>
              {toggleFilter ? (
                <ExpandLess style={{ fontSize: "20px", color: "#5F6D60" }} />
              ) : (
                <ExpandMore style={{ fontSize: "20px", color: "#5F6D60" }} />
              )}
            </div>

            {toggleFilter && (
              <>
                {selectedFilter.name === "Custom" && customOpen === true ? (
                  <div className="absolute bg-white border shadow-md mt-[-50px] w-[350px] right-[-5px] z-[30] rounded-lg px-3 py-3 flex flex-col justify-center gap-0">
                    <div className="flex gap-3">
                      <div className="w-1/2">
                        <p className="text-[#344335] text-sm pb-1">From</p>
                        <input
                          type="date"
                          value={
                            startDate
                              ? moment(startDate).format("YYYY-MM-DD")
                              : ""
                          }
                          onChange={(event) =>
                            setStartDate(new Date(event.target.value))
                          }
                          placeholder="DD/MM/YY"
                          className="outline-none border border-[#96A397] w-full rounded-lg p-2 text-[#344335] font-medium  text-sm placeholder:text-[#96A397]"
                        />
                      </div>
                      <div className="w-1/2">
                        <p className="text-[#344335] text-sm pb-1">To</p>
                        <input
                          type="date"
                          placeholder="DD/MM/YY"
                          onChange={(event) =>
                            setEndDate(new Date(event.target.value))
                          }
                          value={
                            startDate
                              ? moment(endDate).format("YYYY-MM-DD")
                              : ""
                          }
                          className="outline-none border border-[#96A397] w-full rounded-lg p-2 text-[#344335] font-medium  text-sm placeholder:text-[#96A397]"
                        />
                      </div>
                    </div>

                    <div className="mt-3">
                      <DatePicker
                        selected={startDate}
                        onChange={(dates) => {
                          const [start, end] = dates as [
                            Date | null,
                            Date | null
                          ];
                          setStartDate(start);
                          setEndDate(end);
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                        wrapperClassName="datePicker"
                      />
                    </div>

                    <div className="flex justify-between w-full gap-3 items-center mt-0">
                      <p className="text-[#96A397] text-xs font-medium">
                        {getDaysSelected()} Days Selected
                      </p>
                      <div>
                        <button
                          onClick={() => {
                            setToggleFilter(false);
                            setCustomOpen(false);
                            setStartDate(new Date());
                            setEndDate(null);
                          }}
                          className="rounded-lg text-[#5C715E] py-3 text-sm font-medium"
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={handleSaveCustomDate}
                          className="bg-[#2B8C34] disabled:bg-opacity-[0.8] rounded-lg hover:bg-opacity-[0.8] transition-all text-[#FFFFFF] py-2 px-3 text-sm font-medium"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="absolute bg-white shadow-md mt-[10px] w-[135px] right-[-5px] z-[30] rounded-lg px-3 py-3 flex flex-col justify-center gap-0">
                    {" "}
                    {filters?.map((filter, index) => {
                      return (
                        <div
                          onClick={() => {
                            setSelectedFilter(filter);
                            dispatch(handleInsightsFilter(filter));

                            if (filter.name === "Custom") {
                              setCustomOpen(true);
                            } else {
                              setToggleFilter(false);
                            }
                          }}
                          key={index}
                          className={`px-2 py-3 text-sm cursor-pointer font-normal ${
                            insightsFilter?.name === filter?.name
                              ? "text-primary bg-[#EDF7EE] border-primary border rounded-lg "
                              : " text-[#4B564D]  "
                          }`}
                        >
                          <p>{filter?.name}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        }
      />

      <div className="mt-5 bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg p-4 h-full">
        <h4 className="text-[#3E473F] font-medium text-sm">
          Location Breakdown
        </h4>

        {/* TABLE HEADER */}
        <div className="bg-[#F6F7F6] rounded-lg mt-3 p-3 flex gap-3 text-[#5F6D60] text-sm font-medium  ">
          <div className="w-1/3">Location</div>
          <div className="w-1/3">Calls</div>
          <div className="w-1/3">Top insight topic</div>
        </div>
        {/* TABLE DATA */}
        {insightsByLocationLoading ? (
          <div className="animate-pulse w-full mt-4 px-4 pb-4 mb-4">
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
            <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
          </div>
        ) : (
          <>
            {insightsByLocationData.length === 0 ? (
              <div className="flex flex-col min-h-[300px] justify-center items-center h-full w-full">
                <img
                  src={dialog}
                  alt="dialog icon"
                  className="w-[64px] h-[64px] pb-4"
                />
                <p className="font-medium text-sm leading-[18px] text-[#3E473F] ">
                  You have no insight generated yet
                </p>
              </div>
            ) : (
              <>
                {insightsByLocationData?.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="cursor-pointer border-b hover:bg-[#E1F7E3] border-b-[#F6F7F6] rounded-lg p-3 flex gap-3 text-[#3E473F] text-sm font-medium  "
                    >
                      <div className="w-1/3 capitalize">
                        {data?.location || "Nill"}
                      </div>
                      <div className="w-1/3">{data?.call_volume || 0}</div>
                      <div className="w-1/3 capitalize">
                        {data?.top_insight_topic?.replace("_", " ") || "Nill"}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}

        {/* <h4 className="text-primary font-medium text-sm cursor-pointer p-3">
          See More
        </h4> */}
      </div>

      <div className="mt-5 bg-[#FFFFFF] border border-[#E1E6E1] rounded-lg p-4">
        <div>
          <h4 className="text-[#2E332F] text-sm">Insight Topics</h4>

          <h1 className="text-[#2E332F] text-xl font-semibold mt-2">
            {" "}
            {insightsTopicTotal || 0}
          </h1>
        </div>

        <div className="">
          <CallInsightsTable selectedFilter={insightsFilter} />
        </div>
      </div>
    </div>
  );
};

export default CallInsights;
