import React,{useState, useEffect} from 'react';
import Header from '../../components/Header/Header';
import edit_icon from '../../assets/edit.svg'
import close_icon from '../../assets/close_icon.svg'
import {
    Visibility,
    VisibilityOff,
    ExpandMore,
  } from "@mui/icons-material";
 
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { getUserByID, updateCompanyInfo, updateUserProfile, userChangePassword } from '../../redux/Auth/authAction';
import { logOut, resetState } from '../../redux/Auth/Auth';
import { useNavigate } from 'react-router-dom';

const SettingsPage = () => {

    const dispatch = useAppDispatch()
    const { profile }  = useAppSelector((state) => state.auth);
    const userId = profile.user_id
    const [disabled,setDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [isTogglePassword, setIsTogglePassword] = useState(false);
    const [isToggleNewPassword, setIsToggleNewPassword] = useState(false);
    const [isToggleConfirmPassword, setIsToggleConfirmPassword] = useState(false);
    const [firstName,setFirstName] = useState(profile ? profile.first_name : "Test")
    const [lastName,setLastName] = useState(profile ? profile.last_name : "User")
    const [email,setEmail] = useState(profile ? profile.email : "testuser@gmail.com")
    const [companyName,setCompanyName] = useState(profile ? profile.organization.name : "name@companyname.com")
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [typeId, setTypeID] = useState(1);
    const type = [
      {
        id: 1,
        title: "Profile",
      },
      {
        id: 2,
        title: "Company Profile",
      },
      {
        id: 3,
        title: "Password",
      },
    ];

    interface countryType {
        key: number;
        name: string;
      }

    // const country = [
    //     {
    //       key: 1,
    //       name: "Nigeria",
    //     },
    //     {
    //       key: 2,
    //       name: "Ghana",
    //     },
    //     {
    //       key: 3,
    //       name: "Togo",
    //     },
    //   ];
    
   

    const [focusedSelect, setFocusedSelect] = useState<number | null>(null);
    const handleFocus = (index: number) => setFocusedSelect(index);
    const handleBlur = () => setFocusedSelect(null);

    const [selectedCountry] = useState<countryType>({
        key: 0,
        name: profile && profile.organization ? profile.organization?.locations[0]?.country.name : "",
      });
      const [selectedState] = useState<countryType>({
        key: 0,
        name: profile && profile.organization ? profile.organization?.locations[0]?.state.name : "",
      });


    const [keyword, setKeyword] = useState("")
    const [keywords, setKeywords] = useState<string[]>(profile && profile.organization ? profile.organization.services: []);
    const [error, setError] = useState("")

    const handleChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value);
        setError("");
    };

    const isValid = (keyword:string) =>{
        let error = null;
    
        if (isInList(keyword)) {
          error = `${keyword} has already been added.`;
        }
    
        if (error) {
          setError(error)
    
          return false;
        }
         return true;
     }

     const isInList = (val:string) =>{
        return keywords.includes(val);
     }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const trimmedKeyword = keyword.trim();
    
        // Check if Enter or comma was pressed
        if ((event.key === 'Enter' || event.key === ',') && trimmedKeyword) {
            event.preventDefault();
    
            // Check if the keyword is already in the list
            if (trimmedKeyword && isValid(trimmedKeyword)) {
                // Add the new keyword to the keywords array
                setKeywords([...keywords, trimmedKeyword]);
    
                // Clear the input field
                setKeyword('');
            } else {
                // Optionally, handle duplicate keyword error here
                setError('Keyword already exists');
            }
        }
    };
    

    const handleDelete = (item:string) => {
        let resp = keywords.filter(i => i !== item)
        setKeywords(resp)
    };

    const toggleType = (id: number) => {
        setTypeID(id);
      };

      const handleEditEnable = () =>{
        setDisabled(!disabled)
      }

      const handleUpdateUser = () =>{
        setLoading(true)
        dispatch(updateUserProfile({
            first_name: firstName,
            last_name: lastName
        })).then(((res)=>{
            if(res?.type === "updateUserProfile/fulfilled"){
                setLoading(false)
                setDisabled(true)
                dispatch(getUserByID({
                    id: userId
                }))
            }
            else if(res?.type === "updateUserProfile/rejected"){
                setLoading(false)
            }
        }))
      }

      const handleUpdateCompanyInfo = () =>{
        setLoading(true)
        dispatch(updateCompanyInfo({
           company_name: companyName,
           services: keywords 
        })).then(((res)=>{
            if(res?.type === "updateCompanyInfo/fulfilled"){
                setLoading(false)
                setDisabled(true)
                dispatch(getUserByID({
                    id: userId
                }))
            }
            else if(res?.type === "updateCompanyInfo/rejected"){
                setLoading(false)
            }
        }))
      }

      const handleChangePassword = () =>{
        setLoading(true)
        dispatch(userChangePassword({
            current_password: password,
            new_password: newPassword,
        })).then((res)=>{
            if(res?.type === "userChangePassword/fulfilled"){
                setLoading(false)
                dispatch(logOut())
                dispatch(resetState())
                navigate('/')
            }
            else if(res?.type === "userChangePassword/rejected"){
                setLoading(false) 
            }
        })
      }

      useEffect(()=>{
        dispatch(getUserByID({
            id: userId
        }))
      },[dispatch, userId])

    
    return ( 
        <>
         <div>
            <Header title="Settings">
                <div className='mt-4'>
                    <ul className="flex gap-4 items-center">
                    {type.map((val) => (
                        <li
                        key={val.id}
                        onClick={() => toggleType(val.id)}
                        className="cursor-pointer"
                        >
                        <p
                            className={
                            typeId === val.id
                                ? "text-primary text-sm font-medium border-b-2 border-primary"
                                : "text-[#96A397] text-sm font-medium"
                            }
                        >
                            {val.title}
                        </p>
                        </li>
                    ))}
                    </ul>
                </div>
            </Header>
        </div>

       {typeId === 1 &&
        <div className="grid grid-cols-3 gap-4 mt-5">
              <div className="bg-white rounded-lg py-10 px-5 flex flex-col justify-center items-center">

                <div className='bg-[#DBFFDF] w-[128px] h-[128px] rounded-full flex justify-center items-center'>
                        <p className='text-[#2B8C34] text-2xl'>
                        {firstName.charAt(0)}
                        {lastName.charAt(0)}
                        </p>
                </div>
                <div className='mt-2'>
                    <p className='text-[#292621] text-sm font-semibold'>{firstName} {lastName}</p>
                </div>

             </div>

            <div className="col-span-2 bg-white rounded-lg px-5 py-8">
                <div className='flex justify-between items-center'>
                    <div>
                        <p className='text-[#344335] text-sm font-semibold'>Account Information</p>
                    </div>
                   {disabled &&
                    <div>
                        <button onClick={handleEditEnable} className='bg-[#EDF7EE] rounded-lg flex gap-2 items-center text-[#2B8C34] py-3 px-4 text-sm font-medium'>
                            <img src={edit_icon} alt="edit" />
                            Edit Profile
                        </button>
                    </div>
                    }
                </div>

                <div className='grid grid-cols-2 gap-4 mt-3'>
                    <div className="w-full">
                            <p className={` ${disabled ? 'text-[#ABB6AC]':  'text-[#344335]'} text-sm pb-1`}>
                            First Name
                            </p>
                        <div className="relative">
                            <input
                            disabled={disabled}
                            type="text"
                            value={firstName}
                            onChange={(e) =>setFirstName(e.target.value)}
                            className={`rounded-lg w-full outline-none focus:border-primary focus:shadow-1xl px-4 py-3 border bg-white border-[#96A397] disabled:border-[#ABB6AC] bg-transparent disabled:bg-[#F7F7F7] text-[#344335] disabled:text-[#ABB6AC] placeholder:text-[#96A397] text-sm font-medium`}
                            placeholder="First Name"
                            />
                        </div>
                    </div>

                    <div className="w-full">
                            <p className={` ${disabled ? 'text-[#ABB6AC]':  'text-[#344335]'} text-sm pb-1`}>
                            Last Name
                            </p>
                        <div className="relative">
                            <input
                            type="text"
                            disabled={disabled}
                              value={lastName}
                              onChange={(e) =>setLastName(e.target.value)}
                            className={`rounded-lg w-full outline-none focus:border-primary focus:shadow-1xl px-4 py-3 border bg-white border-[#96A397] disabled:border-[#ABB6AC] bg-transparent disabled:bg-[#F7F7F7] text-[#344335] disabled:text-[#ABB6AC] placeholder:text-[#96A397] text-sm font-medium`}
                            placeholder="Last Name"
                            />
                        </div>
                    </div>

                    <div className="w-full">
                            <p className={` ${disabled ? 'text-[#ABB6AC]':  'text-[#344335]'} text-sm pb-1`}>
                            Email Address
                            </p>
                        <div className="relative">
                            <input
                            type="text"
                            disabled
                            value={email}
                            onChange={(e) =>setEmail(e.target.value)}
                            className={`rounded-lg w-full outline-none focus:border-primary focus:shadow-1xl px-4 py-3 border bg-white border-[#96A397] disabled:border-[#ABB6AC] bg-transparent disabled:bg-[#F7F7F7] text-[#344335] disabled:text-[#ABB6AC] placeholder:text-[#96A397] text-sm font-medium`}
                            placeholder="Email address"
                            />
                        </div>
                    </div>

                </div>

                {!disabled &&
                 <div className='mt-6 flex gap-2 items-center justify-end'>
                    <div>
                        <button onClick={() =>setDisabled(true)} className='rounded-lg text-[#5C715E] py-3 px-4 text-sm font-medium'>
                            Cancel
                        </button>
                    </div>
                    <div>
                    <button 
                    disabled={loading}
                    onClick={handleUpdateUser}
                    className='bg-[#2B8C34] disabled:bg-opacity-[0.8] rounded-lg hover:bg-opacity-[0.8] transition-all text-[#FFFFFF] py-3 px-4 text-sm font-medium'>
                            Save Changes
                        </button> 
                    </div>
                </div>
                }
            </div>
        </div>
        }

        {typeId === 2 && 
        <div className="bg-white mt-5 rounded-lg py-5 px-5">
                    <div className='flex justify-between items-center'>
                            <div>
                                <p className='text-[#344335] text-sm font-semibold'>Company Workspace</p>
                            </div>
                            {disabled &&  
                            <div>
                                <button onClick={handleEditEnable} className='bg-[#EDF7EE] rounded-lg flex gap-2 items-center text-[#2B8C34] py-3 px-4 text-sm font-medium'>
                                    <img src={edit_icon} alt="edit" />
                                    Edit Company Profile
                                </button>
                            </div>
                            }
                            
                    </div>

                    <div className='grid grid-cols-3 gap-4 mt-5'>
                        <div>
                            {/* Company name */}
                            <div className="w-full">
                                    <p className={` ${disabled ? 'text-[#ABB6AC]':  'text-[#344335]'} text-sm pb-1`}>
                                    Company Name
                                    </p>
                                <div className="relative">
                                    <input
                                    disabled={disabled}
                                    type="text"
                                    value={companyName}
                                    onChange={(e) =>setCompanyName(e.target.value)}
                                    className={`rounded-lg w-full outline-none focus:border-primary focus:shadow-1xl px-4 py-3 border bg-white border-[#96A397] disabled:border-[#ABB6AC] bg-transparent disabled:bg-[#F7F7F7] text-[#344335] disabled:text-[#ABB6AC] placeholder:text-[#96A397] text-sm font-medium`}
                                    placeholder="First Name"
                                    />
                                </div>
                            </div>

                            {/* Country */}
                            <div className="w-full mt-2">
                                <p className="text-[#ABB6AC] text-sm font-medium leading-[18px] pb-1">
                                Country
                                </p>

                                <div
                                tabIndex={1}
                                onFocus={() => handleFocus(1)}
                                onBlur={handleBlur}
                                // onClick={() => setIsToggleCountry((prev) => !prev)}
                                className={`flex items-center px-4 py-[12px] cursor-pointer   justify-between w-full border rounded-lg bg-[#F7F7F7] border-[#ABB6AC] ${
                                    focusedSelect === 1
                                    ? "focus:border-primary focus:shadow-1xl "
                                    : ""
                                }`}
                                 >
                            <p className="text-sm font-medium leading-[18px] text-[#ABB6AC]">
                                {selectedCountry?.name || "Select Country"}
                            </p>
                          
                                <ExpandMore
                                style={{ color: "#96A397", fontSize: "20px" }}
                                />
                            
                            </div>

                                {/* {isToggleCountry && (
                                <div className="relative">
                                    <div className="absolute top-1 left-1">
                                    <div className="rounded-[10px] bg-white p-3 w-[348px] h-full overflow-y-scroll select_scroll max-h-[248px] shadow z-10">
                                        {country?.map((data, index) => {
                                        return (
                                            <div
                                            className={`p-3 ${index < country.length-1  ? 'mb-[4px]': '' } ${
                                                selectedCountry.name === data.name
                                                ? "border border-primary bg-[#EDF7EE] text-primary rounded-lg cursor-default "
                                                : "text-[#344335] hover:bg-[#EDF7EE] hover:rounded-lg cursor-pointer "
                                            }`}
                                            onClick={() => {
                                                setSelectedCountry(data);
                                                setIsToggleCountry(false);
                                            }}
                                            key={index}
                                            >
                                            <p className="text-sm font-medium leading-[18px] ">
                                                {data.name}
                                            </p>
                                            </div>
                                        );
                                        })}
                                    </div>
                                    </div>
                                </div>
                                )} */}
                            </div>
                            
                            {/* State/Province */}
                            <div className="w-full mt-3">
                                <p className="text-[#ABB6AC] text-sm font-medium leading-[18px] pb-1">
                                    State/Province
                                    </p>

                                <div
                                tabIndex={2}
                                onFocus={() => handleFocus(2)}
                                onBlur={handleBlur}
                                // onClick={() => setIsToggleState((prev) => !prev)}
                                className={`flex items-center px-4 py-3 cursor-pointer   justify-between w-full border rounded-lg bg-[#F7F7F7] border-[#ABB6AC] ${
                                    focusedSelect === 2
                                    ? "focus:border-primary focus:shadow-1xl "
                                    : ""
                                }`}
                                >
                                <p className="text-sm font-medium text-[#ABB6AC]">
                                    {selectedState?.name || "Select State/Province"}
                                </p>
                              
                                    <ExpandMore
                                    style={{ color: "#96A397", fontSize: "20px" }}
                                    />
                                
                                </div>
                            </div>

                        </div>
                        <div className='col-span-2'>
                            <div className="w-full">
                                <label className={` ${disabled ? 'text-[#ABB6AC]':  'text-[#344335]'} text-sm pb-1`}>
                                Service(s) Offered
                                </label>
                                <div className={`rounded-lg ${disabled ? 'border-[#ABB6AC] bg-[#F7F7F7]': 'border-[#96A397] bg-white'} border py-3 px-4 w-full appearance-none min-h-[200px]`}>
                                    <div className='flex flex-wrap items-center'>
                                    <div className="flex flex-wrap gap-2">
                                        {
                                            keywords.length ?
                                            keywords.map((item, index)=>(
                                            <div key={index} className='flex justify-center last:mr-2  items-center bg-[#DBFFDF] gap-2 w-fit rounded-[4px] py-1 px-2'>
                                                <p className='text-[#2B8C34] text-sm font-medium capitalize'>{item}</p>
                                                <img onClick={() => handleDelete(item)} className={`${disabled ? 'pointer-events-none' : 'cursor-pointer'}  w-[10px] h-[10px]`} src={close_icon} alt="clear icon" />
                                            </div> 
                                            ))
                                            :
                                            ""
                                        }
                                    </div>
                                     <input
                                        className='appearance-none w-full flex-1 placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-sm focus:border-[#2B8C34] focus:outline-none focus:shadow-input bg-transparent'
                                        type="text"
                                        disabled={disabled}
                                        placeholder="Input the Service(s) Offered by your Organisation"
                                        value={keyword}
                                        onChange={handleChangeKeyword}
                                        onKeyDown={handleKeyDown}
                                    />
                                    </div>
                                </div>
                                <small className={
                                    error ?
                                    'text-[#B92043] font-medium text-xs' :
                                         'text-[#5c715e] font-medium text-xs' }>
                                    {error ? error : "Use a comma(,) to separate different service(s)" }
                                    </small>
                            </div>
                        </div>
                    </div>

                    {!disabled && 
                    <div className='mt-6 flex gap-2 items-center justify-end'>
                            <div>
                                <button onClick={() =>setDisabled(true)} className='rounded-lg text-[#5C715E] py-3 px-4 text-sm font-medium'>
                                    Cancel
                                </button>
                            </div>
                            <div>
                            <button
                              disabled={loading}
                            onClick={handleUpdateCompanyInfo}
                             className='bg-[#2B8C34] rounded-lg hover:bg-opacity-[0.8]  disabled:bg-opacity-[0.8] transition-all text-[#FFFFFF] py-3 px-4 text-sm font-medium'>
                                    Save Changes
                                </button> 
                            </div>
                        </div>
                        }


                </div>
        }

        {typeId === 3 &&
        <div className="bg-white mt-5 rounded-lg py-5 px-5 max-w-lg">
            <div>
                <p className='text-[#344335] text-sm font-semibold'>Password</p>
            </div>

            <div className="w-full mt-4">
                <p className={`text-[#344335] text-sm font-medium pb-1`}>
                    Current Password
                    </p>
                <div className="relative">
                    <input
                    type={`${isTogglePassword ? "text" : "password"}`}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={`rounded-lg w-full outline-none focus:border-primary focus:shadow-1xl px-4 py-3 border bg-white border-[#96A397] disabled:border-[#ABB6AC] bg-transparent disabled:bg-[#F7F7F7] text-[#344335] disabled:text-[#ABB6AC] placeholder:text-[#96A397] text-sm font-medium`}
                    placeholder="Current Password"
                    />
                     <div className="cursor-pointer right-3 absolute top-1/2 transform -translate-y-1/2">
                        {isTogglePassword ? (
                            <VisibilityOff
                            onClick={() => setIsTogglePassword(!isTogglePassword)}
                            style={{ color: "#96A397", fontSize: "18px" }}
                        />
                        ) : (
                            
                            <Visibility
                            onClick={() => setIsTogglePassword(!isTogglePassword)}
                            style={{ color: "#96A397", fontSize: "18px" }}
                        />
                        )}
                    </div>
                </div>
             </div>

             <div className="w-full mt-4">
                <p className={`text-[#344335] text-sm font-medium pb-1`}>
                New Password
                    </p>
                <div className="relative">
                    <input
                      type={`${isToggleNewPassword ? "text" : "password"}`}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    className={`rounded-lg w-full outline-none focus:border-primary focus:shadow-1xl px-4 py-3 border bg-white border-[#96A397] disabled:border-[#ABB6AC] bg-transparent disabled:bg-[#F7F7F7] text-[#344335] disabled:text-[#ABB6AC] placeholder:text-[#96A397] text-sm font-medium`}
                    placeholder="New Password"
                    />
                       <div className="cursor-pointer right-3 absolute top-1/2 transform -translate-y-1/2">
                        {isToggleNewPassword ? (
                            <VisibilityOff
                            onClick={() => setIsToggleNewPassword(!isToggleNewPassword)}
                            style={{ color: "#96A397", fontSize: "18px" }}
                        />
                        ) : (
                            
                            <Visibility
                            onClick={() => setIsToggleNewPassword(!isToggleNewPassword)}
                            style={{ color: "#96A397", fontSize: "18px" }}
                        />
                        )}
                    </div>
                </div>
             </div>

             <div className="w-full mt-4">
                <p className={`text-[#344335] text-sm font-medium pb-1`}>
                Confirm Password
                    </p>
                <div className="relative">
                    <input
                     type={`${isToggleConfirmPassword ? "text" : "password"}`}
                     value={confirmPassword}
                     onChange={(e) => setConfirmPassword(e.target.value)}
                    className={`rounded-lg w-full outline-none focus:border-primary focus:shadow-1xl px-4 py-3 border bg-white border-[#96A397] disabled:border-[#ABB6AC] bg-transparent disabled:bg-[#F7F7F7] text-[#344335] disabled:text-[#ABB6AC] placeholder:text-[#96A397] text-sm font-medium`}
                    placeholder="Confirm Password"
                    />
                       <div className="cursor-pointer right-3 absolute top-1/2 transform -translate-y-1/2">
                        {isToggleConfirmPassword ? (
                            <VisibilityOff
                            onClick={() => setIsToggleConfirmPassword(!isToggleConfirmPassword)}
                            style={{ color: "#96A397", fontSize: "18px" }}
                        />
                        ) : (
                            
                            <Visibility
                            onClick={() => setIsToggleConfirmPassword(!isToggleConfirmPassword)}
                            style={{ color: "#96A397", fontSize: "18px" }}
                        />
                        )}
                    </div>
                </div>
             </div>

             <div className='mt-5'>
             <button 
                    disabled={loading}
                    onClick={handleChangePassword}
                    className='bg-[#2B8C34] disabled:bg-opacity-[0.8] rounded-lg hover:bg-opacity-[0.8] transition-all text-[#FFFFFF] py-3 px-4 text-sm font-medium'>
                            Change Password
                        </button> 
             </div>


        </div>
        }


        </>
     );
}
 
export default SettingsPage;