import React, { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { handleLogin } from "../../redux/Auth/authAction";

const SignIn = () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading, isAuth } = useAppSelector((state) => state.auth);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isTogglePassword, setIsTogglePassword] = useState(false);

  const isButtonEnabled = !loading && emailRegex.test(email) && password;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && isButtonEnabled) {
      handleSignIn();
    }
  };

  const handleSignIn = () => {
    const credentials = {
      username: email,
      password: password,
    };
    dispatch(handleLogin(credentials)).then((res) => {
      if (res.type !== "handleLogin/rejected") {
        navigate("/overview");
      }
    });
  };

  useEffect(() => {
    if (isAuth) {
      navigate("/overview");
    }
  }, [isAuth, navigate]);

  return (
    <>
      <div className="flex flex-col">
        <p className="text-[#181B19] text-[30px] font-semibold leading-[36px]">
          Welcome Back
        </p>
        <p className="text-disabled text-sm font-normal leading-5 pb-[32px] pt-1 w-[383px]">
          Enter your credentials below to access your account
        </p>
        <div className="flex flex-col gap-[20px] pb-5">
          <div className="w-full">
            <p className="text-disabled text-sm font-medium leading-[18px] pb-1">
              Work Email
            </p>
            <input
              type="text"
              className="rounded-lg border focus:border-primary focus:shadow-1xl w-full border-[#96A397] outline-none bg-white px-4 py-3 placeholder:text-[#96A397] text-sm font-medium leading-[18px]"
              onChange={(event) => setEmail(event?.target.value)}
              onKeyDown={handleKeyDown}
              pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
              placeholder="name@company.com"
            />
          </div>
          <div className="flex gap-[20px]">
            <div className="w-full">
              <p className="text-disabled text-sm font-medium leading-[18px] pb-1">
                Password
              </p>
              <div className="relative">
                <input
                  value={password}
                  type={`${isTogglePassword ? "text" : "password"}`}
                  onChange={(event) => setPassword(event?.target.value)}
                  onKeyDown={handleKeyDown}
                  className="rounded-lg w-full focus:border-primary focus:shadow-1xl outline-none px-4 py-3 border bg-white border-[#96A397] bg-transparent text-[#344335] placeholder:text-[#96A397] text-sm font-medium"
                  placeholder="Password"
                />
                <div className="cursor-pointer right-3 absolute top-1/2 transform -translate-y-1/2">
                  {isTogglePassword ? (
                    <VisibilityOff
                      onClick={() => setIsTogglePassword(!isTogglePassword)}
                      style={{ color: "#96A397", fontSize: "18px" }}
                    />
                  ) : (
                    <Visibility
                      onClick={() => setIsTogglePassword(!isTogglePassword)}
                      style={{ color: "#96A397", fontSize: "18px" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <Link to="/forgot-password">
            <p className="text-[#344335] text-[14px] font-normal leading-[18px]">
              Forgot your password?
            </p>
          </Link>
          <div className="">
            <button
              onClick={handleSignIn}
              disabled={!isButtonEnabled}
              className="outline-none w-full px-[32px] py-[16px] rounded-lg bg-primary disabled:bg-[#5F6D60] text-white text-sm font-medium leading-[18px]"
            >
              {loading ? "Loading..." : "Log in"}
            </button>
          </div>

          <Link to="/sign-up">
            <div className="flex justify-center items-center cursor-pointer">
              {" "}
              <p className="text-[#5F6D60] text-sm font-medium leading-[18px]">
                Don’t have an account?{" "}
                <span className="text-primary">Sign Up</span>
              </p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SignIn;
