import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

type insightGrapghType = {
  id: number;
  farmerId?: number;
  page?: number;
  from?: string | Date;
  to?: string | Date;
};

type insightTopicType = {
  from?: string | Date;
  to?: string | Date;
};

export const insightsGraph = createAsyncThunk(
  "insightsGraph",
  async (
    { id, farmerId, from, to }: insightGrapghType,
    { rejectWithValue, dispatch }
  ) => {
    try {
      if (farmerId) {
        const res = await axios.get(
          `${apiUrl}insights/0/overview/monthly-call-volume/?farmer_id=${farmerId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200 || res.status === 201) {
          return res.data;
        }
      } else {
        const res = await axios.get(
          `${apiUrl}insights/${id}/overview/monthly-call-volume?from_date=${from}&to_date=${to}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200 || res.status === 201) {
          return res.data;
        }
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
      } else if (error.response && error.response.status === 400) {
        toast.error(error?.response?.data?.message, {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else {
        toast.error(
          "Something went wrong while trying to retrieve insights graph data",
          {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          }
        );
        return rejectWithValue(error);
      }
    }
  }
);

export const insightsConversation = createAsyncThunk(
  "insightsConversation",
  async (
    { id, farmerId, page, from, to }: insightGrapghType,
    { rejectWithValue, dispatch }
  ) => {
    try {
      if (farmerId) {
        const res = await axios.get(
          `${apiUrl}insights/0/overview/conversations/?farmer_id=${farmerId}&page=${page}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200 || res.status === 201) {
          return res.data;
        }
      } else {
        const res = await axios.get(
          `${apiUrl}insights/${id}/overview/conversations?page=${page}&from_date=${from}&to_date=${to}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 200 || res.status === 201) {
          return res.data;
        }
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
      } else if (error.response && error.response.status === 400) {
        toast.error(error?.response?.data?.message, {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else {
        toast.error(
          "Something went wrong while trying to retrieve insights conversation data",
          {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          }
        );
        return rejectWithValue(error);
      }
    }
  }
);

export const insightsByLocation = createAsyncThunk(
  "insightsByLocation",
  async ({ from, to }: insightTopicType, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(
        `${apiUrl}insights/overview/location-breakdown?from_date=${from}&to_date=${to}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: "auth/logOut" });
      } else if (error.response && error.response.status === 400) {
        toast.error(error?.response?.data?.message, {
          style: {
            border: "1px solid #B92043",
            backgroundColor: "#B92043",
            color: "#FFFFFF",
            fontSize: 14,
            maxWidth: 400,
          },
        });
        return rejectWithValue(error);
      } else {
        toast.error(
          "Something went wrong while trying to retrieve insights by location data",
          {
            style: {
              border: "1px solid #B92043",
              backgroundColor: "#B92043",
              color: "#FFFFFF",
              fontSize: 14,
              maxWidth: 400,
            },
          }
        );
        return rejectWithValue(error);
      }
    }
  }
);
